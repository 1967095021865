import React from "react";
import { GoGear } from "react-icons/go";

function Maintenance() {
  return (
    <div className="fixed w-full inset-0 z-50 flex items-center justify-center">
      <div className="absolute top-0 left-0 w-full h-32 bg-white z-1"></div> 
      <div className="absolute w-full h-full inset-0 pointer-events-none z-2">
        <div className="absolute top-0 left-0 w-full h-5 bg-warning-pattern"></div>
        <div className="absolute bottom-0 left-0 w-full h-5 bg-warning-pattern"></div>
        <div className="absolute top-0 left-0 h-full w-5 bg-warning-pattern"></div>
        <div className="absolute top-0 right-0 h-full w-5 bg-warning-pattern"></div>
      </div>
      <div className="absolute top-1/4 left-1/4 text-gray-400 opacity-50 z-0 gear">
        <GoGear className="gearT" />
      </div>
      <div className="absolute top-1/4 left-1/4 text-gray-400 opacity-50 z-0 gear2">
        <GoGear className="gearT2" />
      </div>
      <div className="absolute top-1/4 left-1/4 text-gray-400 opacity-50 z-0 gear3">
        <GoGear className="gearT3" />
      </div>
      <div className="relative bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-lg h-auto z-10">
        <h1 className="text-4xl font-bold mb-4 text-primary">
          ¡Estamos realizando mantenimiento!
        </h1>
        <p className="text-xl mb-6">
          Estamos trabajando para mejorar la experiencia. Vuelve pronto.
        </p>
        <div className="mb-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto animate-bounce text-primary"
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="6" x2="12" y2="12" />
            <line x1="12" y1="12" x2="16" y2="12" />
          </svg>
        </div>
        <p className="text-sm text-gray-500">Gracias por tu paciencia.</p>
      </div>
    </div>
  );
}

export default Maintenance;

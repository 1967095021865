import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import CustomErrorModal from "../components/sharedComponents/CustomErrorModal";
import candado from "../assets/images/candado.png";
import warningLogo from "../assets/images/warningLogo.png";
import { GoArrowLeft } from "react-icons/go";
import { post } from "aws-amplify/api";
import Auth from "../utils/auth";
export default function Verification() {
  const movil =
    useSelector((state) => state.phoneNumber) || localStorage.getItem("phone");
  const imei =
    useSelector((state) => state.imei) || localStorage.getItem("imei");
  const [counter, setCounter] = useState(300);
  const minutes = Math.floor(counter / 60);
  const seconds = counter % 60;
  const displayTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  const lastFourDigits = movil ? movil.slice(-4) : "";
  const [showErrorModal, setErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [enableBtn, setEnableBtn] = useState(true);
  const [fzaVta, setFzaVta] = useState("");

  /**
   * useEffect hook to handle authentication, code validation, and countdown timer.
   *
   * This hook runs whenever the `counter` or `code` values change.
   * - If the user is not logged in, it navigates to the "/home" route.
   * - It validates the `code` using the `codeValidation` function and enables or disables the submit button accordingly.
   * - If the `counter` is greater than 0, it sets a timeout to decrement the `counter` by 1 every second.
   */
  
  let isDist = localStorage.getItem("mtd")
  useEffect(() => {

    if(isDist){   
    const Fza = JSON.parse(localStorage.getItem("mtd"));
    const FzaDinamico = Fza.fzaVtaPadre ? Fza.fzaVtaPadre : "";
    setFzaVta(FzaDinamico);
    }

    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    codeValidation(code) ? setEnableBtn(false) : setEnableBtn(true);
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter, code]);
  

  /**
   * Asynchronously handles the resending of the OTP.
   *
   * This function sends a POST request to the "/sendOTP" endpoint with the phone number and IMEI.
   * It logs the response if the request is successful. If an error occurs, it parses the error response.
   */
  async function handleResendOtp() {
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/sendOTP",
        options: {
          body: {
            phoneNumber: movil,
            imei: imei,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      console.log("OTP ", response);
    } catch (e) {
      const errorBody = JSON.parse(e.response.body);
    }
  }

  /**
   * Asynchronously validates the OTP code.
   *
   * This function sends a POST request to the "/validateCode" endpoint with the provided code and phone number.
   * If the response status is "03", it sets an error modal to true. If the response status is "0", it navigates
   * to the "/cotizacion" route. If an error occurs, it handles the specific error status "03" by logging an error
   * message and setting the error modal to true.
   */
  async function validateCode() {
    console.log("validating code...");
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/validateCode",
        options: {
          body: {
            code: code,
            phone: movil,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      if (response.status === "03") {
        setErrorModal(true);
      } else if (response.status === "0") {
        navigate("/cotizacion");
      }
    } catch (e) {
      const errorBody = JSON.parse(e.response.body);
      if (errorBody.status === "03") {
        console.log("Error while validating OTP");
        setErrorModal(true);
      }
    }
  }

  const codeValidation = (value) => {
    let codeRegex = /^[0-9]{6}$/;
    return codeRegex.test(value);
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    validateCode();
  };

  const handleBlur = (e) => {
    codeValidation(e.target.value)
      ? setErrorMsg("")
      : setErrorMsg(
          "El código que ingresaste no es válido, por favor, revísalo e intenta de nuevo."
        );
  };

  const handleChange = (e) => {
    setErrorMsg("");
    setCode(e.target.value);
  };

  /**
   * Checks if the user is logged in and navigates to the home page if not.
   *
   * This code snippet checks if the user is authenticated using the `Auth.loggedIn()` method.
   * If the user is not logged in, it navigates to the "/home" route and returns to prevent further execution.
   */
  if (!Auth.loggedIn()) {
    navigate("/home");
    return;
  }

  const buttonClass =
    counter === 0
      ? "font-thin text-[#6D80A4] mt-3 font-bold py-2 px-4 rounded-md md:py-3 md:px-6"
      : "cursor-not-allowed font-thin mt-3 text-[#6D80A4] text-opacity-60 font-bold py-2 px-4 rounded-md md:py-3 md:px-6";
  return (
    <section className="relative min-h-screen font-gotham place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      { isDist ?
      <p className="absolute bg-primary text-white px-3 py-1 top-0 right-0 rounded-bl-lg">
        
        { fzaVta }
      </p> : 
      null
      }
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-5 phone:pt-5 tablet:pt-8">
        <button onClick={() => navigate("/quiero-proteccionmovil")}>
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div>
      <Banner />
      <form
        onSubmit={handleCodeSubmit}
        className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10 "
      >
        <h1 className="laptop:text-2xl phone:text-base tablet:text-xl text-primary font-semibold laptop:py-6 text-center phone:px-8 laptop:px-0">
          Ingresa el código que te enviamos por SMS{" "}
          <br className="phone:hidden laptop:block" /> al número ****
          {lastFourDigits}:
        </h1>

        <div className="flex flex-col justify-start items-center text-left pt-10 text-center">
          <div className="flex flex-col items-start">
            <label className="pb-2 text-[#6D80A4] laptop:text-lg">
              Código de verificación
            </label>
            <input
              type="text"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }} 
              value={code}
              maxLength={6} 
              placeholder="6 dígitos"
              pattern="\d*"
              inputMode="numeric"
              className="laptop:w-96 phone:w-80 h-10 border-2 border-[#6D80A4] border-opacity-50 rounded-md px-2"
            />
            {errorMsg ? (
              <div className="flex flex-row items-center ">
                <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                <span className="w-72 text-red-500 text-xs pt-2 text-left">
                  {errorMsg}
                </span>
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-col justify-center items-center pt-8 text-sm laptop:text-base laptop:py-[5rem] text-center">
          <button
            disabled={enableBtn}
            className={`shadow-md font-thin ${
              enableBtn
                ? "bg-orange-300 hover:bg-orange-300"
                : "bg-orng hover:bg-orange-700"
            } text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48`}
          >
            Continuar
          </button>
          <button
            className={buttonClass}
            type="button"
            onClick={() => {
              setCounter(300);
              handleResendOtp();
            }}
            disabled={counter !== 0}
          >
            {counter === 0
              ? "Generar otro código"
              : "Código vigente por " + displayTime}
          </button>
        </div>
      </form>
      <CustomErrorModal
        isVisible={showErrorModal}
        onClose={() => setErrorModal(false)}
        color="alert"
      >
        <div className="py-5 px-5 laptop:px-7 text-left">
          <div className="flex justify-center mb-4 mt-4">
            <img src={candado} alt="" className="text-center w-9 h-10" />
          </div>

          <h1 className="text-center text-primary font-semibold mb-2">
            Código no valido
          </h1>
          <div className="w-auto rounded-full h-[0.3rem] bg-alert bg-opacity-25 mb-3"></div>
          <p className="mb-4 text-base font-regular text-primary text-center">
            El código de verificación que ingresaste no es válido o ha expirado
          </p>
          <div
            className="space-y-0 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              onClick={() => {
                setErrorModal(false);
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-primary border border-primary border-1 bg-white hover:bg-gray-100 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-md text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center phone:mr-2"
            >
              Cerrar
            </button>

            <button
              onClick={() => {
                handleResendOtp();
                setCode("");
                setCounter(300);
                setErrorModal(false);
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-white bg-[#6D80A4] hover:bg-opacity-85
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center"
            >
              Generar código
            </button>
          </div>
        </div>
      </CustomErrorModal>
    </section>
  );
}

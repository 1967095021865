import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CaptchaComponent = ({ onCaptchaResolved,onClose }) => {
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaValue, setCaptchaValue] = useState(generateCaptcha());
  const [errorMessage, setErrorMessage] = useState(""); 

  function generateCaptcha() {
    return Math.random().toString(36).substring(2, 7).toUpperCase();
  }

  const handleCaptchaValidation = () => {
    if (captchaInput === captchaValue) {
      setErrorMessage(""); 
      onCaptchaResolved(); 
      onClose();
    } else {
      setErrorMessage("Captcha incorrecto. Intente de nuevo."); 
      setCaptchaInput("");
      setCaptchaValue(generateCaptcha()); 
    }
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <p className="text-[#6D80A4]  text-*/4 font-sans text-left mt-2">Resuelve el CAPTCHA y da click en Validar para confirmar que recibiste el pago.</p>
        <div style={{ fontSize: "24px", margin: "10px 0", fontWeight: "bold" }}>
          {captchaValue}
        </div>
        <input
          type="text"
          placeholder="Ingrese el CAPTCHA"
          value={captchaInput}
          onChange={(e) => setCaptchaInput(e.target.value.toUpperCase())}
          onPaste={(e) => e.preventDefault()}
          onCopy={(e) => e.preventDefault()} 
          style={{ padding: "10px", fontSize: "16px", width: "100%" }}
          className="border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
        {errorMessage && (
          <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
            {errorMessage}
          </p>
        )}
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={handleCaptchaValidation}
            className="shadow-lg font-thin bg-orange-500 hover:bg-orange-700 w-3/4 text-white py-2 rounded-md"
          >
            Validar
          </button>
          <button
            onClick={onClose}
            className="mt-2 py-2 w-full text-blue-600 text-center font-medium rounded-md hover:text-blue-400"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

const modalStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const modalContentStyles = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  textAlign: "center",
  maxWidth: "400px",
  width: "100%",
};

export default CaptchaComponent;

import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { jsPDF } from "jspdf";
import encabezado from "../../assets/images/pMovilAmigoKit.png";
import top from "../../assets/images/mobileTopBanner.jpg";
import Gotham from "../../assets/fonts/Ghotam-Book.txt";
import logo from "../../assets/images/telcelsvg.png";
import lista from "../../assets/images/contratacion_icon.png";
import simbDin from "../../assets/images/coberturas_icon.png";

const PdfGen = forwardRef(
  ({ name, email, cash, imei, FzaP, Fza, user }, ref) => {
    const [fontBase64, setFontBase64] = useState("");

    useEffect(() => {
      setFontBase64(Gotham);
    }, []);

    console.log(fontBase64);
    const generatePDF = () => {
      return new Promise((resolve, reject) => {
        try {
          const doc = new jsPDF();

          const now = new Date();
          const day = String(now.getDate()).padStart(2, "0");
          const month = String(now.getMonth() + 1).padStart(2, "0"); // Meses van de 0 a 11
          const year = String(now.getFullYear()).slice(-2); // Últimos 2 dígitos del año
          const year2 = String(now.getFullYear());
          const hours = String(now.getHours()).padStart(2, "0");
          const minutes = String(now.getMinutes()).padStart(2, "0");
          const seconds = String(now.getSeconds()).padStart(2, "0");

          const currentDateTime = `${day}/${month}/${year} | ${hours}:${minutes}`;

          const footerY = 280;
          const footerHeight = 20;
          doc.addFileToVFS("Gotham.ttf", fontBase64);
          doc.addFont("Gotham.ttf", "Gotham", "normal");

          const imgWidth = 40;
          const imgHeight = 20;
          const pageWidth = doc.internal.pageSize.width;
          const imgX = 10;
          const imgY = 13;
          doc.addImage(top, "JPEG", 0, 0, 300, 8);
          doc.addImage(encabezado, "JPEG", imgX, imgY, imgWidth, imgHeight);
          doc.addImage(logo, "PNG", 160, 13, imgWidth, imgHeight);

          doc.setFillColor(239, 250, 254);
          doc.rect(0, 40, 300, 15, "F");

          doc.setFont("helvetica", "bold");
          doc.setFontSize(20);
          doc.setTextColor(0, 47, 135);
          doc.text(`Recepción de pago`, 75, 50);

          doc.setFillColor(253, 245, 232);
          doc.rect(10, 70, 190, 10, "F");
          doc.addImage(lista, "PNG", 20, 72, 5, 6);

          doc.setFontSize(12);
          doc.setTextColor(109, 128, 164);
          doc.text(`Detalles de la contratación`, 28, 76.5);

          doc.setFontSize(9);
          doc.text("Fecha y hora:", 153, 60);
          doc.text(currentDateTime, 174, 60);

          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.setTextColor(109, 128, 164);
          doc.text(`Titular`, 28, 90);
          doc.setFont("helvetica", "normal");
          doc.text(name, 28, 95);

          doc.setFont("helvetica", "bold");
          doc.setTextColor(109, 128, 164);
          doc.text(`Correo Electrónico`, 28, 105);
          doc.setFont("helvetica", "normal");
          doc.text(email, 28, 110);

          doc.setFont("helvetica", "bold");
          doc.text(`IMEI`, 28, 120);
          doc.setFont("helvetica", "normal");
          doc.text(imei, 28, 125);

          doc.setFont("helvetica", "bold");
          doc.text(`Fuerza de Venta`, 28, 135);
          doc.setFont("helvetica", "normal");
          doc.text(FzaP, 28, 140);

          doc.setFont("helvetica", "bold");
          doc.text(`Usuario`, 28, 150);
          doc.setFont("helvetica", "normal");
          doc.text(user, 28, 155);

          doc.setFillColor(253, 245, 232);
          doc.rect(10, 175, 190, 10, "F");
          doc.addImage(simbDin, "PNG", 20, 177, 4, 5);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.setTextColor(109, 128, 164);
          doc.text(`Detalles del pago`, 28, 181);

          doc.setFont("helvetica", "bold");
          doc.text(`Monto Pagado`, 28, 196);
          doc.setFont("helvetica", "normal");
          doc.text(`$${cash}`, 28, 201);

          doc.setFillColor(0, 47, 135);
          doc.rect(0, footerY, pageWidth, footerHeight, "F");
          doc.setTextColor(255, 255, 255);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);

          const footerText =
            "© Radiomóvil Dipsa S.A de C.V. Todos los derechos reservados.";
          const textWidth = doc.getTextWidth(footerText);
          const textX = (pageWidth - textWidth) / 2;
          doc.text(footerText, textX, footerY + 7);

          doc.save(`Comprobante_de_Pago_R9_${imei}_${year2}${month}${day}.pdf`);
          resolve("PDF generado y descargado");
        } catch (error) {
          reject(error);
        }
      });
    };

    useImperativeHandle(ref, () => ({
      generatePDF,
    }));

    return null;
  }
);

export default PdfGen;

import { useState, useEffect } from 'react';
import { get } from "aws-amplify/api";  
const useMaintenanceStatus = () => {
  const [isMaintenance, setIsMaintenance] = useState(null);  

  async function getStatusMaintenance() {
    try {
      const restOperation = get({
        apiName: "ppmrestapi",
        path: "/maintenance-status",
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      setIsMaintenance(response.maintenanceMode);  
      console.log("response", response);
    } catch (error) {
      console.error("Error obteniendo el estado de mantenimiento:", error);
    }
  }

  useEffect(() => {
    getStatusMaintenance();
  }, []);

  return isMaintenance;  
};

export default useMaintenanceStatus;

import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import { useSelector } from "react-redux";
import { GoArrowLeft } from "react-icons/go";
import Auth from "../utils/auth";
export default function CashPaymentConfirmation2() {
  const [fzaVta, setFzaVta] = useState("");
  const username = useSelector((state) => state.titular);
  const titular = username ? username : localStorage.getItem("cash_titular");
  const cashAmount = localStorage.getItem("cash_amount");
  const comission = localStorage.getItem("comission");
  const lastFourDigits = localStorage.getItem("lastFourDigits");
  const reference = localStorage.getItem("cash_id");
  const email = localStorage.getItem("email");
  let barCode = localStorage.getItem("barcode") || "false";
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Initialize your variable
  let isDist = localStorage.getItem("mtd");

  useEffect(() => {
    const Fza = JSON.parse(localStorage.getItem("mtd"));
    const FzaDinamico = Fza.fzaVtaPadre;
    setFzaVta(FzaDinamico);
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
  }, []);

  const handleContinueClick = async (e) => {
    e.preventDefault();
    if (isDist) {
      localStorage.clear();
      window.location.href =
        "https://www.distribuidor.telcel.com:4475/Portal-Distribuidores/app/login";
    } else {
      navigate("/home");
    }
  };

  const expireAt = localStorage.getItem("expirationDate"); // 2024-10-05 16:13"
  const date = new Date(expireAt);

  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const month = monthNames[date.getMonth()];

  const formattedDate = `${day} de ${month} de ${year} a las ${hour}:${
    minute < 10 ? "0" : ""
  }${minute}`;
  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    const timer = setTimeout(() => {
      setIsLoading(false); // Set your variable to false after 5 seconds
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8 ">
      {isDist ? (
        <p className="absolute bg-primary text-white px-3 py-1 top-0 right-0 rounded-bl-lg">
          {fzaVta}
        </p>
      ) : null}
      <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col  justify-center item-center phone:row-span-4  laptop:col-span-6 py-10 ">
          <h1 className="text-2xl   text-primary font-semibold phone:px-8 text-center mb-8">
            Contratación en proceso
          </h1>
          <p className="text-xl texto-barras text-[#6D80A4] phone:px-8 laptop:w-[50vw] text-center text-base/5 mb-3">
            Los datos se registraron con éxito. <br />
            Indica a tu cliente que debe realizar el pago en caja para <br />
            finalizar la contratación del servicio.
          </p>
          <div className="flex flex-col justify-center laptop:items-start phone:items-start codigo-barras">
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-1 font-thin px-6 phone:px-9 ">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Titular: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {" "}
                {titular}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold"> Correo electrónico: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {email}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-bold">
                {" "}
                <span>Monto a pagar: </span>{" "}
              </p>
              <p className="pl-1 text-[#6D80A4] laptop:text-xl phone:text-base ">
                {"   "}${cashAmount}{" "}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Referencia: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {reference}
              </p>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="text-center laptop:w-[50vw] phone:w-50  phoneS:w-100 tablet:w-100 transform scale-50"
            dangerouslySetInnerHTML={{ __html: barCode }}
          />
          <p className="text-center laptop:w-[50vw] mt-[-20px]">{reference}</p>
          
          <div className="pt-8 text-sm laptop:text-base laptop:pt-[2rem] laptop:pb-[1rem] text-center">
            <button
              onClick={handleContinueClick}
              className=" font-thin bg-orange-500 hover:bg-orng text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48"
            >
              Finalizar
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

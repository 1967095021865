import React, { useEffect, useState } from "react";
import Banner from "../components/sharedComponents/Banner";
import InsuranceDataModal from "../components/InsuranceDataModal";
import { useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import Auth from "../utils/auth";
export default function InsuranceData() {
  const navigate = useNavigate();
  const [fzaVta, setFzaVta] = useState("");
  let isDist = localStorage.getItem("mtd")

  useEffect(() => {
    if(isDist){   
      const Fza = JSON.parse(localStorage.getItem("mtd"));
      const FzaDinamico = Fza.fzaVtaPadre ? Fza.fzaVtaPadre : "";
      setFzaVta(FzaDinamico);
      }

    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
  }, []);

  return (
    <section className="relative min-h-screen font-gotham place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      { isDist ?
      <p className="absolute bg-primary text-white px-3 py-1 top-0 right-0 rounded-bl-lg">
        {fzaVta}
      </p> : 
      null
      }
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-5 phone:pt-5 tablet:pt-8">
        <button onClick={() => navigate("/cotizacion")}>
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div>

      <Banner />
      <div className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10  phone:mx-3">
        <h1 className="laptop:text-2xl tablet:text-2xl phone:text-lg text-primary font-semibold laptop:py-6 text-center phone:py-5 laptop:py-0">
          Completa tus datos y elige una forma de pago
        </h1>
        <InsuranceDataModal />
      </div>
    </section>
  );
}

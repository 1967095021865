import React, { useState, useEffect } from "react";
import Spinner from "./sharedComponents/Spinner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TransparentSpinner from "./sharedComponents/TransparentSpinner";
import { post } from "aws-amplify/api";
import CustomModal from "./sharedComponents/CustomModal";
import CustomErrorModal from "./sharedComponents/CustomErrorModal";
import warning from "../assets/images/warningLogo.png";
import warningLogo from "../assets/images/warningLogo.png";

export default function UserData() {
  const dispatch = useDispatch();

  const userImei = useSelector((state) => state.imei);
  const globalSubscriberName = localStorage.getItem("subscriberName") || "";
  const birthdate = localStorage.getItem("birthdate") || "";
  const email = localStorage.getItem("email") || "";
  const street = localStorage.getItem("street") || "";
  const numberExt = localStorage.getItem("numberExt") || "";
  const numberInt = localStorage.getItem("numberInt") || "";
  const colony = localStorage.getItem("colony") || "";
  const postalCode = localStorage.getItem("postalCode") || "";
  const city = localStorage.getItem("city") || "";
  const estate = localStorage.getItem("estate") || "";
  const rfc = localStorage.getItem("rfc") || "";
  const curp = localStorage.getItem("curp") || "";
  const subscriberName = localStorage.getItem("subscriberName");
  const globalPhone = useSelector((state) => state.phone);
  const userPhone = globalPhone ? globalPhone : localStorage.getItem("phone");
  const mtd = localStorage.getItem("mtd");
  const mtdData = mtd ? JSON.parse(mtd) : "";
  const subscriberFirstLastName = localStorage.getItem(
    "subscriberFirstLastName"
  );
  const subscriberSecondLastName = localStorage.getItem(
    "subscriberSecondLastName"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showCurpModal, setCurpModal] = useState(false);
  const [errorWhileCurp, setErrorWhileCurp] = useState(false);
  const [accesCurpModal, setAccesCurpModal] = useState(false);
  const [dataComplete, setDataComplete] = useState(false);
  const [paymentProcessDetails, setPaymentProcessDetails] = useState({
    sessionKey: "",
    webSessionID: "",
  });

  const [form, setForm] = useState({
    name: subscriberName ? subscriberName : "",
    paternalSurname: subscriberFirstLastName ? subscriberFirstLastName : "",
    maternalSurname: subscriberSecondLastName ? subscriberSecondLastName : "",
    birthdate: birthdate ? birthdate : "",
    rfc: rfc ? rfc : "",
    curp: curp ? curp : "",
    phone: userPhone ? userPhone : "",
    email: email ? email : "",
    street: street ? street : "",
    numberExt: numberExt ? numberExt : "",
    numberInt: numberInt ? numberInt : "",
    colony: colony ? colony : "",
    postalCode: postalCode ? postalCode : "",
    city: city ? city : "",
    estate: estate ? estate : "",
    imei: userImei ? userImei : localStorage.getItem("subscriberIMEI"), //userImei,
  });

  const [errors, setErrors] = useState({
    name: "",
    paternalSurname: "",
    maternalSurname: "",
    birthdate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value;
    if (['name', 'paternalSurname', 'maternalSurname'].includes(name)) {
      inputValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    } else if (name === 'postalCode') {
      inputValue = inputValue.replace(/[^0-9]/g, '');
    }
    setForm((prevForm) => ({
      ...prevForm,
      [name]: inputValue,
    }));

    
    if (name !== "email" && name !== "birthdate") {
      inputValue = inputValue.replace(
        /[+\-=*!@#$%^&()<>?\/;:'"\[\]{}|\\_`~áÁéÉíÍóÓúÚ]/g,
        ""
      );
    }
    setFormInvalid(false);
    setIsNameInvalid(false);
    setIsMaternalSurnameInvalid(false);
    setIsPaternalSurnameInvalid(false);
    setIsBirthdateInvalid(false);
    setIsRfcInvalid(false);
    setIsCurpInvalid(false);
    setIsPhoneInvalid(false);
    setIsEmailInvalid(false);
    setIsRfcInvalid(false);
    setIsCurpInvalid(false);
    setIsStreetNameInvalid(false);
    setIsExteriorNumberInvalid(false);
    setIsInteriorNumberInvalid(false);
    setIsPostalCodeInvalid(false);
    setIsColonyInvalid(false);
    setIsCityInvalid(false);
    setIsEstateInvalid(false);
    setForm({ ...form, [e.target.name]: inputValue });
    console.log("TT ", form);
    setErrors({ [e.target.name]: "" });
    setNewErrorCurp("");
  };

  const [curpDetails, setCurpDetails] = useState({
    gender: "",
    birthState: "",
  });

  const handleCurpDetailsChange = (e) => {
    setCurpDetails({ ...curpDetails, [e.target.name]: e.target.value });
    if (curpDetails.gender !== "" && curpDetails.birthState !== "") {
      setEnableBtn(false);
    }
    //console.log("A) CURP DETAILS: ", curpDetails);
  };

  // const processPayment = async (e) => {
  //   try {
  //     console.log("getting curp");
  //     const response = await fetch(
  //       "https://zpryy1jgve.execute-api.us-east-1.amazonaws.com/dev/processpayment",
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     const data = await response.json();
  //     console.log("Payment response: ", data);

  //     if (data.code === "001" || data.code === "002" || data.code === "003") {
  //       console.log("Error while processing payment");
  //       // setPolicyException(true);
  //     } else if (data.code === "0") {
  //       setPaymentProcessDetails({
  //         ...paymentProcessDetails,
  //         sessionKey: data.data.sessionKey,
  //         webSessionID: data.data.webSessionID,
  //       });
  //       console.log(paymentProcessDetails);
  //       // setOtpSent(true);
  //     }
  //   } catch (error) {
  //     throw new Error("An error occurred while validating IMEI", error);
  //   }
  // };

  //New Curp request

  async function getCurp() {
    console.log("validating");
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/getRenapo",
        options: {
          body: {
            cveEntFedNac: curpDetails.birthState,
            fechaNacimiento: form.birthdate,
            nombres: form.name,
            primerApellido: form.paternalSurname,
            segundoApellido: form.maternalSurname,
            sexo: curpDetails.gender,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      if (
        response.code === "001" ||
        response.code === "002" ||
        response.code === "003"
      ) {
        console.log("Error while getting CURP");
        // setPolicyException(true);
      } else if (response.code === "000") {
        //console.log("Hell yeah: ", data.data.curp);
        setForm({ ...form, curp: response.data.curp });
        setCurpModal(false);
        // setOtpSent(true);
      }

      // console.log("POST call succeeded");
      // console.log(response);
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      if (errorBody.code === "003") {
        setErrorWhileCurp(true);
        setEnableBtn(true);
      }
    }
  }

  // New RFC request
  async function getRFC() {
    console.log("validating");
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/getrfc",
        options: {
          body: {
            fechaNacimiento: form.birthdate,
            nombres: form.name,
            primerApellido: form.paternalSurname,
            segundoApellido: form.maternalSurname,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      if (
        response.code === "001" ||
        response.code === "002" ||
        response.code === "003"
      ) {
        console.log("Error while getting RFC");
        // setPolicyException(true);
      } else if (response.code === "0") {
        setForm({ ...form, rfc: response.data.RFC });
      }

      // console.log("POST call succeeded");
      // console.log(response);
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);
    }
  }

  async function getInsurance() {
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/insurance",
        options: {
          body: {
            name: form.name,
            paternalSurname: form.paternalSurname,
            maternalSurname: form.maternalSurname,
            birthdate: form.birthdate,
            rfc: form.rfc,
            curp: form.curp,
            phone: form.phone,
            email: form.email,
            street: form.street,
            numberExt: form.numberExt,
            numberInt: form.numberInt,
            colony: form.colony,
            postalCode: form.postalCode,
            city: form.city,
            estate: form.estate,
            imei: localStorage.getItem("imei"), //form.imei,
            mtdData: mtdData ? mtdData : null,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      if (
        response.code === "001" ||
        response.code === "002" ||
        response.code === "003"
      ) {
        console.log("Error while getting insurance");
        // setPolicyException(true);
      } else if (response.code === "0") {
        localStorage.setItem("userDataComplete", JSON.stringify(true));
        localStorage.setItem("policy", response.data.policy);
        localStorage.setItem("email", form.email);
        window.location.reload();

        setDataComplete(true);
        dispatch({
          type: "SET_POLICY",
          payload: localStorage.getItem("policy"),
        });
        localStorage.setItem("subscriberName", form.name);
        localStorage.setItem("subscriberFirstLastName", form.paternalSurname);
        localStorage.setItem("subscriberSecondLastName", form.maternalSurname);
        localStorage.setItem("birthdate", form.birthdate);
        localStorage.setItem("email", form.email);
        localStorage.setItem("street", form.street);
        localStorage.setItem("numberExt", form.numberExt);
        localStorage.setItem("numberInt", form.numberInt);
        localStorage.setItem("colony", form.colony);
        localStorage.setItem("postalCode", form.postalCode);
        localStorage.setItem("city", form.city);
        localStorage.setItem("estate", form.estate);
        localStorage.setItem("rfc", form.rfc);
        localStorage.setItem("curp", form.curp);
      }
      // console.log("POST call succeeded");
      // console.log(response);
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);
    }
  }

  const [isFormInvalid, setFormInvalid] = useState(false);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isPaternalSurnameInvalid, setIsPaternalSurnameInvalid] =
    useState(false);
  const [isMaternalSurnameInvalid, setIsMaternalSurnameInvalid] =
    useState(false);
  const [isBirthdateInvalid, setIsBirthdateInvalid] = useState(false);
  const [isRfcInvalid, setIsRfcInvalid] = useState(false);
  const [isCurpInvalid, setIsCurpInvalid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isExteriorNumberInvalid, setIsExteriorNumberInvalid] = useState(false);
  const [isInteriorNumberInvalid, setIsInteriorNumberInvalid] = useState(false);
  const [isPostalCodeInvalid, setIsPostalCodeInvalid] = useState(false);
  const [isStreetNameInvalid, setIsStreetNameInvalid] = useState(false);
  const [isColonyInvalid, setIsColonyInvalid] = useState(false);
  const [isCityInvalid, setIsCityInvalid] = useState(false);
  const [isEstateInvalid, setIsEstateInvalid] = useState(false);
  const [isPhoneInvalid, setIsPhoneInvalid] = useState(false);
  const [enableBtn, setEnableBtn] = useState(true);
  const userValidation = (name, value) => {
    if (name === "date") {
      const datePattern = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
      return datePattern.test(value);
    } else if (name === "postalCode") {
      const postalCodePattern = /^[0-9]{5}$/;
      return postalCodePattern.test(value);
    } else if (name === "cardNumber") {
      const cardNumberPattern = /^[0-9]{14,19}$/;
      return cardNumberPattern.test(value);
    } else if (
      name === "name" ||
      name === "paternalSurname" ||
      name === "maternalSurname"
    ) {
      const namePattern = /^[a-zA-ZñÑ]+(([',. -][a-zA-Z ñÑ])?[a-zA-ZñÑ]*)*$/;
      return namePattern.test(value);
    } else if (name === "birthdate") {
      const birthdatePattern =
        /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/;
      return birthdatePattern.test(value);
    } else if (name === "RFC") {
      const rfcPattern = /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/;
      return rfcPattern.test(value);
    } else if (name === "email") {
      const emailPattern = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
      return emailPattern.test(value);
    } else if (name === "colony" || name === "city" || name === "estate") {
      const streetNamePattern = /^[a-zA-Z\s\.\-]+$/;
      return streetNamePattern.test(value);
    } else if (name === "exteriorNumber" || name === "interiorNumber") {
      const exteriorNumberPattern = /^[0-9a-zA-Z]+$/;
      return exteriorNumberPattern.test(value);
    } else if (name === "phoneNumber") {
      const phoneNumberPattern = /^[0-9]{10}$/;
      return phoneNumberPattern.test(value);
    } else if (name === "curp") {
      const curpPattern = /^[A-Z0-9]{18}$/;
      return curpPattern.test(value);
    } else if (name === "streetName") {
      const streetNamePattern = /^[a-zA-Z0-9\s\.\-]+$/;
      return streetNamePattern.test(value);
    }
    // Add more validation checks for other fields here
  };

  const isFormValid = (form) => {
    return Object.values(form).every((value) => value !== "");
  };

  const handleInsuranceSubmit = (e) => {
    let isValid = true;
    e.preventDefault();

    if (!userValidation("name", form.name)) {
      console.log("Invalid Name");
      setIsNameInvalid(true);
      isValid = false;
    }

    if (!userValidation("paternalSurname", form.paternalSurname)) {
      console.log("Invalid Paternal");
      setIsPaternalSurnameInvalid(true);
      isValid = false;
    }

    if (!userValidation("maternalSurname", form.maternalSurname)) {
      console.log("Invalid Maternal");
      setIsMaternalSurnameInvalid(true);
      isValid = false;
    }

    if (!userValidation("birthdate", form.birthdate)) {
      console.log("Invalid bitthdate");
      setIsBirthdateInvalid(true);
      isValid = false;
    }

    if (!userValidation("RFC", form.rfc)) {
      console.log("Invalid rfc");
      setIsRfcInvalid(true);
      isValid = false;
    }

    if (!userValidation("curp", form.curp)) {
      console.log("Invalid curp");
      setIsCurpInvalid(true);
      isValid = false;
    }

    if (!userValidation("email", form.email)) {
      console.log("Invalid email");
      setIsEmailInvalid(true);
      isValid = false;
    }

    if (!userValidation("phoneNumber", form.phone)) {
      console.log("Invalid phone");
      setIsPhoneInvalid(true);
      isValid = false;
    }

    if (!userValidation("streetName", form.street)) {
      console.log("Invalid street");
      setIsStreetNameInvalid(true);
      isValid = false;
    }

    if (!userValidation("exteriorNumber", form.numberExt)) {
      console.log("Invalid street");
      setIsExteriorNumberInvalid(true);
      isValid = false;
    }

    if (!userValidation("interiorNumber", form.numberInt)) {
      console.log("Invalid street");
      setIsInteriorNumberInvalid(true);
      isValid = false;
    }

    if (!userValidation("colony", form.colony)) {
      console.log("Invalid colony");
      setIsColonyInvalid(true);
      isValid = false;
    }

    if (!userValidation("postalCode", form.postalCode)) {
      console.log("Invalid cp");
      setIsPostalCodeInvalid(true);
      isValid = false;
    }

    if (!userValidation("city", form.city)) {
      console.log("Invalid cp");
      setIsCityInvalid(true);
      isValid = false;
    }

    if (!userValidation("estate", form.estate)) {
      console.log("Invalid cp");
      setIsEstateInvalid(true);
      isValid = false;
    }

    if (!isValid) {
      setFormInvalid(true);
      return;
    }
    console.log("Form: ", form);
    if (isFormValid(form)) {
      //console.log("LMAO");
      setIsLoading(true);
      getInsurance().finally(() => setIsLoading(false));
      //processPayment();
    }
  };

  useEffect(() => {
    if (curpDetails.gender !== "" && curpDetails.birthState !== "") {
      setEnableBtn(false);
    } else {
      setEnableBtn(true);
    }
  }, [isFormInvalid, curpDetails.gender, curpDetails.birthState]);

  const handleCurpSubmit = (e) => {
    e.preventDefault();
    if (curpDetails.gender !== "" && curpDetails.birthState !== "") {
      setIsLoading(true);
      getCurp().finally(() => setIsLoading(false));
      //setForm({ ...form, curp: "CUBA960625HQRRLL06" });
      setCurpModal(false);
    } else {
      console.log("Please fill all the fields");
    }
  };

  const validateRfcInputs = (e) => {
    let newErrors = {
      name: "",
      fatherLastName: "",
      motherLastName: "",
      birthdate: "",
    };
    if (form.name === "") {
      newErrors.name = "Por favor ingresa tu nombre";
    }
    if (form.paternalSurname === "") {
      newErrors.fatherLastName = "Por favor ingresa tu apellido paterno";
    }
    if (form.maternalSurname === "") {
      newErrors.motherLastName = "Por favor ingresa tu apellido materno";
    }
    if (form.birthdate === "") {
      newErrors.birthdate = "Por favor ingresa tu fecha de nacimiento";
    }
    setErrors(newErrors);

    if (
      form.name !== "" &&
      form.paternalSurname !== "" &&
      form.maternalSurname !== "" &&
      form.birthdate !== ""
    ) {
      getRFC();
    }
  };

  const validation = (name, value) => {
    let nameRegex = /^[a-zA-Z\s-]+$/;

    if (
      name === "name" ||
      name === "paternalSurname" ||
      name === "maternalSurname"
    ) {
      return nameRegex.test(value);
    }
  };

  const [newErrorCurp, setNewErrorCurp] = useState("");
  const triggerCurpModal = (e) => {
    // setEnableBtn(true);
    setCurpDetails({ gender: "", birthState: "" });
    let newErrors = {
      name: "",
      paternalSurname: "",
      maternalSurname: "",
      birthdate: "",
    };

    const fields = [
      { key: "name", error: "Por favor, ingresa tu nombre" },
      {
        key: "paternalSurname",
        error: "Por favor, ingresa tu apellido paterno",
      },
      {
        key: "maternalSurname",
        error: "Por favor, ingresa tu apellido materno",
      },
      { key: "birthdate", error: "Por favor, ingresa tu fecha de nacimiento" },
    ];

    fields.forEach((field) => {
      if (!validation(field.key, form[field.key])) {
        newErrors[field.key] = field.error;
        // setNewErrorCurp(
        //   "Porfavor ingrese o verifique su nombre, apellido paterno, apellido materno y fecha de nacimiento."
        // );
      }
    });

    // setErrors(newErrors);

    if (
      form.name !== "" &&
      form.paternalSurname !== "" &&
      form.maternalSurname !== "" &&
      form.birthdate !== ""
    ) {
      setCurpModal(true);
    } else {
      setNewErrorCurp(
        "Por favor, ingresa tu nombre, apellido paterno, apellido materno y fecha de nacimiento"
      );
    }
  };

  return (
    <div className="text-[#6D80A4] flex flex-col items-center justify-center my-2">
      {isLoading ? (
        <TransparentSpinner />
      ) : (
        <form
          onSubmit={handleInsuranceSubmit}
          className="w-full mx-auto rounded p-5"
        >
          <div className="flex flex-row">
            <div className="flex flex-col mb-2 mr-2 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">
                Nombre(s)
              </label>
              <input
                type="text"
                name="name"
                placeholder="Nombre(s)"
                onChange={handleChange}
                value={form.name}
                maxLength={250}
                // className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${
                //   isNameInvalid ? "border-red-500" : "border-gray-300"
                // } rounded-md focus:outline-none focus:border-indigo-500`}
                className={`border w-full px-3 py-2 placeholder-gray-300 text-[13.4px] ${
                  isNameInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
              />
              {errors.name && (
                <span className="text-red-500 text-xs">{errors.name}</span>
              )}
            </div>
            <div className="flex flex-col mb-2 mr-2 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">
                Apellido paterno
              </label>
              <input
                type="text"
                maxLength={250}
                name="paternalSurname"
                placeholder="apellido paterno"
                value={form.paternalSurname}
                onChange={handleChange}
                className={`border w-full px-3 py-2 placeholder-gray-300 text-[13.4px] ${
                  isPaternalSurnameInvalid
                    ? "border-red-500"
                    : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
              />
              {errors.paternalSurname && (
                <span className="text-red-500 text-xs">
                  {errors.paternalSurname}
                </span>
              )}
            </div>
          </div>
          {/* <div className="flex flex-row">
          <div className="flex flex-col mb-4">
            <label className="text-primary pb-1">Nombre(s)</label>
            <input
              type="text"
              name="name"
              placeholder="Nombre(s)"
              onChange={handleChange}
              value={form.name}
              className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            {errors.name && (
              <span className="text-red-500 text-xs">{errors.name}</span>
            )}
          </div>
          <div className="flex flex-col mb-4 mr-1">
            <label className="text-primary pb-1">Apellido Paterno</label>
            <input
              type="text"
              name="paternalSurname"
              placeholder="apellido paterno"
              value={form.paternalSurname}
              onChange={handleChange}
              className="mr-2 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            {errors.fatherLastName && (
              <span className="text-red-500 text-xs pt-2">
                {errors.fatherLastName}
              </span>
            )}
          </div>
        </div> */}

          {/* <input
          type="text"
          name="phone"
          placeholder="Apellidos"
          onChange={handleChange}
          className="mb-4 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
        /> */}

          <div className="flex justify-between text-left mt-1 w-full pl-1">
            <label className="pb-1 laptop:text-sm phone:text-xs">
              Apellido materno
            </label>
            <label className="pb-1 laptop:mr-24 tablet:mr-24 phone:mr-4 laptop:text-sm phone:text-xs">
              Fecha de nacimiento
            </label>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col mb-4 mr-2 w-full">
              <input
                type="text"
                name="maternalSurname"
                placeholder="apellido materno"
                value={form.maternalSurname}
                maxLength={250}
                onChange={handleChange}
                className={`border w-full px-3 py-2 placeholder-gray-300 text-[13.4px] ${
                  isMaternalSurnameInvalid
                    ? "border-red-500"
                    : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className={`border w-full  px-3 py-2 placeholder-gray-300  ${
                //   isMaternalSurnameInvalid
                //     ? "border-red-500"
                //     : "border-gray-300"
                // }rounded-md focus:outline-none focus:border-indigo-500`}
              />
              {errors.maternalSurname && (
                <span className="text-red-500 text-xs pt-2">
                  {errors.maternalSurname}
                </span>
              )}
            </div>
            <div className="flex flex-col mb-4 mr-1 w-full">
              <input
                type="date"
                name="birthdate"
                placeholder="Fecha de nacimeinto"
                onChange={handleChange}
                value={form.birthdate}
                className={`border w-full laptop:px-3 phone:px-[0.2rem] py-2 placeholder-gray-300 ${
                  isBirthdateInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
              {errors.birthdate && (
                <span className="text-red-500 text-xs">{errors.birthdate}</span>
              )}
            </div>
          </div>

          {/* <div className="flex flex-row justify-between">
            <div className="flex flex-col mb-4 mr-2 w-full ">
              <label className="pb-1">Apellido materno</label>
              <input
                type="text"
                name="maternalSurname"
                placeholder="apellido materno"
                value={form.maternalSurname}
                onChange={handleChange}
                className={`border w-full px-3 py-2 placeholder-gray-300 ${
                  isMaternalSurnameInvalid
                    ? "border-red-500"
                    : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className={`border w-full  px-3 py-2 placeholder-gray-300  ${
                //   isMaternalSurnameInvalid
                //     ? "border-red-500"
                //     : "border-gray-300"
                // }rounded-md focus:outline-none focus:border-indigo-500`}
              />
              {errors.maternalSurname && (
                <span className="text-red-500 text-xs pt-2">
                  {errors.maternalSurname}
                </span>
              )}
            </div>

            <div className="flex flex-col mb-4 w-full ">
              <label className="pb-1">Fecha de nacimiento</label>
              <input
                type="date"
                name="birthdate"
                placeholder="Fecha de nacimeinto"
                onChange={handleChange}
                value={form.birthdate}
                className={`border w-full px-3 py-2 placeholder-gray-300 ${
                  isBirthdateInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
              {errors.birthdate && (
                <span className="text-red-500 text-xs">{errors.birthdate}</span>
              )}
            </div>
          </div> */}

          {/* <div className="flex flex-row justify-between mb-4"> */}
          {/* <div className="flex flex-col phone:pr-2 ">
            <label className="text-xs text-primary mb-1">
              Fecha de nacimeinto
            </label>
            <input
              type="date"
              name="birthdate"
              placeholder="Fecha de nacimeinto"
              onChange={handleChange}
              className="mr-2 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            {errors.birthdate && (
              <span className="text-red-500 text-xs">{errors.birthdate}</span>
            )}
          </div> */}

          <div className="flex flex-row">
            <div className="flex flex-col mb-4 mr-2 w-full ">
              <div className="flex flex-row items-center justify-between">
                <label className="pb-1 mr-1 laptop:text-sm phone:text-xs">
                  RFC
                </label>
                <p
                  className="phone:hidden laptop:block pb-1 laptop:text-xs phone:text-[0.7rem] text-blue-400 cursor-pointer"
                  onClick={validateRfcInputs}
                >
                  Generar mi RFC
                </p>

                <p
                  className="laptop:hidden phone:block pb-1 laptop:text-xs phone:text-[0.7rem] text-blue-400 cursor-pointer"
                  onClick={validateRfcInputs}
                >
                  Generar
                </p>
              </div>

              <input
                type="text"
                name="rfc"
                placeholder="RFC"
                onChange={handleChange}
                value={form.rfc}
                maxLength={250}
                className={`border w-full px-3 py-2 placeholder-gray-300 ${
                  isRfcInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="flex flex-col mb-4 w-full ">
              <div className="relative flex flex-row items-center justify-between">
                <label className="pb-1 mr-1 laptop:text-sm phone:text-xs">
                  CURP
                </label>
                <div className="flex flex-col">
                  {/* <div className="relative inline-block group  ml-2">
                    <span className="flex items-center text-center justify-center w-3 h-3 bg-[#6D80A4] text-white rounded-full cursor-pointer text-[0.6rem]">
                      i
                    </span>
                    <div className="absolute hidden group-hover:block -top-1/2 left-full ml-2 laptop:w-[22vw] phone:w-72 p-2 bg-tooltip text-[#6D80A4] text-xs rounded shadow-lg">
                      Para obtenerlo marca *#06# o encuéntralo en la caja de tu
                      smartphone.
                    </div>
                  </div> */}
                  <div
                    className={`absolute -top-1/2  laptop:right-[8rem] phone:right-[2rem] ml-2 laptop:w-[22vw] phone:w-72 p-2 bg-tooltip text-[#6D80A4] text-xs rounded shadow-lg ${
                      newErrorCurp ? "block" : "hidden"
                    }`}
                  >
                    {newErrorCurp && (
                      <span className="text-red-500 laptop:text-xs phone:text-[0.7rem]">
                        {newErrorCurp}
                      </span>
                    )}
                  </div>

                  <p
                    className="phone:hidden laptop:block pb-1 laptop:text-xs phone:text-[0.7rem] text-blue-400 cursor-pointer "
                    onClick={triggerCurpModal}
                  >
                    Generar mi CURP
                  </p>

                  <p
                    className="laptop:hidden phone:block pb-1 laptop:text-xs phone:text-[0.7rem] text-blue-400 cursor-pointer "
                    onClick={triggerCurpModal}
                  >
                    Generar{" "}
                  </p>
                </div>
              </div>

              <input
                type="text"
                name="curp"
                maxLength={250}
                placeholder="CURP"
                value={form.curp}
                onChange={handleChange}
                className={`border w-full px-3 py-2 placeholder-gray-300 ${
                  isCurpInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
          </div>
          {/* <div className="flex flex-col mr-2">
            <label className="text-sm text-gray-600 invisible">RFC</label>
            <input
              type="text"
              name="rfc"
              placeholder="RFC"
              onChange={handleChange}
              value={form.rfc}
              className="mb-1 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <p
              className="text-xs text-blue-400 cursor-pointer"
              onClick={validateRfcInputs}
            >
              Calcular RFC
            </p>
          </div> */}
          {/* <div className="flex flex-col mr-2">
            <label className="text-sm text-gray-600 invisible">RFC</label>
            <input
              type="text"
              name="curp"
              placeholder="CURP"
              value={form.curp}
              onChange={handleChange}
              className="mb-1 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <p
              className="text-xs text-blue-400 cursor-pointer"
              onClick={triggerCurpModal}
            >
              No conozco mi CURP
            </p>
          </div> */}
          {/* </div> */}
          <div className="flex flex-row">
            <div className="flex flex-col mb-4 mr-2 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">
                Número Telcel
              </label>
              <input
                type="text"
                name="phone"
                disabled={true}
                placeholder="10 dígitos"
                onChange={handleChange}
                value={form.phone}
                className={`border w-full px-3 py-2 placeholder-gray-300 ${
                  isPhoneInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="flex flex-col mb-4 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">
                Correo electrónico
              </label>
              <input
                type="text"
                name="email"
                maxLength={250}
                placeholder="ejemplo@mail.com"
                onChange={handleChange}
                value={form.email}
                className={`border w-full px-3 py-2 placeholder-gray-300 text-[13.4px] ${
                  isEmailInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
          </div>

          {/* <div className="flex flex-row mb-4 justify-between"> */}
          {/* <div className="flex flex-col mr-2">
            <input
              type="text"
              name="curp"
              placeholder="CURP"
              value={form.curp}
              onChange={handleChange}
              className="mb-1 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <p
              className="text-xs text-blue-400 cursor-pointer"
              onClick={triggerCurpModal}
            >
              No conozco mi CURP
            </p>
          </div> */}

          {/* <div className="flex flex-col">
            <input
              type="text"
              name="phone"
              placeholder="Número Telcel"
              onChange={handleChange}
              value={form.phone}
              className="mb-1 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
          </div>
        </div> */}

          {/* <input
          type="text"
          name="email"
          placeholder="Correo Electrónico"
          onChange={handleChange}
          className="mb-4 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
        /> */}

          {/* <h1 className="text-base pb-2">Domicilio</h1> */}

          <div className="flex flex-row">
            <div className="flex flex-col mb-4 mr-2 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">Calle</label>
              <input
                type="text"
                name="street"
                maxLength={250}
                placeholder="Calle"
                onChange={handleChange}
                value={form.street}
                className={`border w-full px-3 py-2 placeholder-gray-300 text-[13.4px] ${
                  isStreetNameInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="flex flex-col mb-4 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">
                Número exterior
              </label>
              <input
                type="text"
                name="numberExt"
                maxLength={250}
                placeholder="Número exterior"
                onChange={handleChange}
                value={form.numberExt}
                className={`border w-full px-3 py-2 placeholder-gray-300 ${
                  isExteriorNumberInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col mb-4 mr-2 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">
                Número interior
              </label>
              <input
                type="text"
                name="numberInt"
                placeholder="Número interior"
                onChange={handleChange}
                maxLength={250}
                value={form.numberInt}
                className={`border w-full px-3 py-2 placeholder-gray-300 ${
                  isInteriorNumberInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="mr-2 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>

            <div className="flex flex-col mb-4 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">
                Colonia
              </label>
              <input
                type="text"
                name="colony"
                maxLength={250}
                placeholder="Colonia"
                onChange={handleChange}
                value={form.colony}
                className={`border w-full px-3 py-2 placeholder-gray-300 text-[13.4px] ${
                  isColonyInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="flex flex-col mb-4 mr-2 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">
                Código Postal
              </label>
              <input
                type="text"
                name="postalCode"
                maxLength={5}
                placeholder="Código Postal"
                onChange={handleChange}
                value={form.postalCode}
                className={`border w-full mb-4 mr-2  px-3 py-2 placeholder-gray-300 ${
                  isPostalCodeInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>

            <div className="flex flex-col mb-4 w-full ">
              <label className="pb-1 laptop:text-sm phone:text-xs">
                Alcaldía ó municipio
              </label>
              <input
                type="text"
                maxLength={250}
                name="city"
                placeholder="Alcaldía o Municipio"
                onChange={handleChange}
                value={form.city}
                className={`border w-full mb-4 mr-2  px-3 py-2 placeholder-gray-300 text-[13.4px] ${
                  isCityInvalid ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:border-indigo-500`}
                // className="mb-4 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
          </div>

          <select
            name="estate"
            placeholder="Estado de nacimeinto"
            onChange={handleChange}
            value={form.estate}
            className={`border w-full mb-4 mr-2  px-3 py-2 placeholder-gray-300 ${
              isEstateInvalid ? "border-red-500" : "border-gray-300"
            } rounded-md focus:outline-none focus:border-indigo-500`}
            // className="mb-4 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          >
            <option value="">Estado</option>
            <option value="Aguascalientes">Aguascalientes</option>
            <option value="Baja California">Baja California</option>
            <option value="Baja California Sur">Baja California Sur</option>
            <option value="Campeche">Campeche</option>
            <option value="Coahuila">Coahuila</option>
            <option value="Colima">Colima</option>
            <option value="Chiapas">Chiapas</option>
            <option value="Chihuahua">Chihuahua</option>
            <option value="Distrito FederalDF">Distrito Federal</option>
            <option value="Durango">Durango</option>
            <option value="Guanajuato">Guanajuato</option>
            <option value="Guerrero">Guerrero</option>
            <option value="Hidalgo">Hidalgo</option>
            <option value="Jalisco">Jalisco</option>
            <option value="Mexico">Mexico</option>
            <option value="Michoacan">Michoacan</option>
            <option value="Morelos">Morelos</option>
            <option value="Nayarit">Nayarit</option>
            <option value="Nuevo León">Nuevo León</option>
            <option value="Oaxaca">Oaxaca</option>
            <option value="Puebla">Puebla</option>
            <option value="Queretaro">Querétaro</option>
            <option value="Quintana Roo">Quintana Roo</option>
            <option value="San Luis Potosi">San Luis Potosí</option>
            <option value="Sinaloa">Sinaloa</option>
            <option value="Sonora">Sonora</option>
            <option value="Tabasco">Tabasco</option>
            <option value="Tamaulipas">Tamaulipas</option>
            <option value="Tlaxcala">Tlaxcala</option>
            <option value="Veracurz">Veracurz</option>
            <option value="Yucatán">Yucatán</option>
            <option value="Zacatecas">Zacatecas</option>
          </select>

          {isFormInvalid ? (
            <div className="flex laptop:justify-start phone:justify-center items-center mb-3 text-center">
              <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
              <p className="text-red-500 text-xs">
                Por favor, verifica que todos los campos estén correctamente
                llenados.
              </p>
            </div>
          ) : null}

          <div className="flex laptop:justify-end phone:justify-center">
            <button
              type="submit"
              className="laptop:w-[15vw] phone:w-[50vw]  px-3 py-2 text-white bg-orange-500 rounded-md hover:bg-primary focus:outline-none"
            >
              Continuar
            </button>
          </div>
        </form>
      )}
      <CustomModal
        isVisible={showCurpModal}
        onClose={() => setCurpModal(false)}
        color="orng"
      >
        <div className="py-1 px-3 text-center">
          <h3 className="mb-4 mt-4 text-md font-thin text-[#6D80A4] text-center">
            Selecciona los siguientes datos para generar tu CURP
          </h3>
          <form onSubmit={handleCurpSubmit} className="space-y-6" action="#">
            <div className="flex justify-evenly items-center">
              <div className="w-48 laptop:pr-[6vw] phone:pr-[30vw]">
                <label className="text-sm text-[#6D80A4]">Sexo</label>
              </div>
              <div className="w-48">
                <label className="text-sm text-[#6D80A4]">
                  Estado de nacimiento
                </label>
              </div>
            </div>
            <div className="flex justify-evenly">
              <select
                name="gender"
                onChange={handleCurpDetailsChange}
                className="mb-4 w-36 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-[#6D80A4] text-[#6D80A4]"
              >
                <option value="">Selecciona</option>
                <option value="H">Masculino</option>
                <option value="M">Femenino</option>
              </select>
              <select
                name="birthState"
                placeholder="Estado de nacimeinto"
                onChange={handleCurpDetailsChange}
                className="mb-4 w-36 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-[#6D80A4] text-[#6D80A4]"
              >
                <option value="">Selecciona</option>
                <option value="AS">Aguascalientes</option>
                <option value="BC">Baja California</option>
                <option value="BS">Baja California Sur</option>
                <option value="CC">Campeche</option>
                <option value="CL">Coahuila</option>
                <option value="CM">Colima</option>
                <option value="CS">Chiapas</option>
                <option value="CH">Chihuahua</option>
                <option value="DF">Distrito Federal</option>
                <option value="DG">Durango</option>
                <option value="GT">Guanajuato</option>
                <option value="GR">Guerrero</option>
                <option value="HG">Hidalgo</option>
                <option value="JC">Jalisco</option>
                <option value="MC">Mexico</option>
                <option value="MN">Michoacan</option>
                <option value="MS">Morelos</option>
                <option value="Nt">Nayarit</option>
                <option value="NL">Nuevo León</option>
                <option value="OC">Oaxaca</option>
                <option value="PL">Puebla</option>
                <option value="QT">Querétaro</option>
                <option value="QR">Quintana Roo</option>
                <option value="SP">San Luis Potosí</option>
                <option value="SL">Sinaloa</option>
                <option value="SR">Sonora</option>
                <option value="TC">Tabasco</option>
                <option value="TS">Tamaulipas</option>
                <option value="TL">Tlaxcala</option>
                <option value="VZ">Veracurz</option>
                <option value="YN">Yucatán</option>
                <option value="ZS">Zacatecas</option>
              </select>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={enableBtn}
                className={`mb-4 ${
                  enableBtn
                    ? "bg-orange-300 cursor-not-allowed"
                    : "bg-orng hover:bg-orange-700 "
                } rounded-md laptop:w-64 phone:w-48 text-white  
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounde-lg text-sm px-5 py-2.5 text-center`}
              >
                Aceptar
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <CustomErrorModal
        isVisible={errorWhileCurp}
        onClose={() => setErrorWhileCurp(false)}
      >
        <div className="font-gotham flex flex-col items-center py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={warning} alt="" className="text-center w-10 h-10" />
          </div>

          <h1 className="text-center text-lg text-[#6D80A4] font-semibold mb-2">
            Error consultando CURP
          </h1>
          <div className="laptop:w-96 phone:w-64 laptop:w-[20rem] rounded-full h-[0.3rem] bg-[#F45753] bg-opacity-25 mb-3"></div>
          <div className="flex justify-center tems-center laptop:w-[22rem] phone:w-64 ">
            <p className="  mb-4 text-base font-regular text-[#6D80A4] text-center">
              Los datos ingresados no son correctos, verifica e intenta de
              nuevo.
            </p>
          </div>

          <div
            className="space-y-6 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              onClick={() => {
                setErrorWhileCurp(false);
              }}
              className="laptop:w-[10vw] phone:w-[50vw] tablet:w-[30vw] text-white bg-[#6D80A4] hover:bg-opacity-75 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-base px-5 phone:px-3 py-2 text-center"
            >
              Aceptar
            </button>
          </div>
        </div>
      </CustomErrorModal>
    </div>
  );
}
